import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import { initSentry } from "./config/sentry";

import microfrontendLayout from "./microfrontend-layout.html";

import "./css/main.css";
import "./css/normalize.css";

initSentry();

const originalFetch = window.fetch;
window.fetch = function (input, config = {}) {
  let deviceId = window.localStorage.getItem("x-flash-device-id");
  if (!deviceId) {
    deviceId = crypto.randomUUID();
    window.localStorage.setItem("x-flash-device-id", deviceId);
  }
  config = config || {};
  config.headers = config.headers || {};
  config.headers["X-FLASH-DEVICE-ID"] = deviceId;
  return originalFetch(input, config);
};

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
