import * as Sentry from "@sentry/browser";

type EventMetadata = {
  dsn: string;
  team?: string;
  projectName?: string;
};

export function initSentry() {
  const mode = process.env.STAGE;
  if (mode === "staging" || mode === "production") {
    Sentry.init({
      dsn: "https://94c808c76f524f4ebec26a9b375c80bd@o266934.ingest.sentry.io/4505370465665024",
      environment: mode,
      integrations: [Sentry.moduleMetadataIntegration()],
      transport: Sentry.makeMultiplexedTransport(
        Sentry.makeFetchTransport,
        dsnFromProjectEvent
      ),
      beforeSend: (event) => {
        const metadatas = getEventMetadata(event)[0];
        // if has metadata inject the dsn on extra object
        if (metadatas) {
          event.extra = {
            ...event.extra,
            project: metadatas.dsn,
            team: metadatas.team,
          };
        }
        return event;
      },
    });
  }
}

/**
 * Get the project dsn from event fired on each MFE
 * @returns an array of string of DSNs. With this, you can handle the same error on multiples sentry projects.
 */
function dsnFromProjectEvent({ getEvent }): string[] {
  return [getEvent()?.extra?.project];
}

/**
 *
 * @param event Sentry event fired
 * @description Every mfe needs to have webpack plugin with ModuleMetadata returning
 * the dsn, team and project name
 * @returns project team, project dsn, and project name
 */
function getEventMetadata(event: Sentry.Event): EventMetadata[] {
  const frames = event?.exception?.values?.[0].stacktrace.frames || [];
  const metadatas: EventMetadata[] = frames
    .filter((frame) => frame.module_metadata)
    .map((frame) => ({
      team: frame.module_metadata.team,
      dsn: frame.module_metadata.dsn,
      projectName: frame.module_metadata.projectName,
    }));
  return metadatas;
}
